import React from "react";
import block from "bem-cn";

import "./TransactionItem.scss";

const b = block("transaction-item");

const TransactionItem = ({ item, isFirst }) => {
  return (
    <>
      <div
        className={b({
          left: true,
        })}
      >
        {item.email}
      </div>
      <div className={b()}>{item.date}</div>
      <div className={b({ isLow: item.amount < 0, isHigh: item.amount >= 0 })}>{item.amount}</div>
      <div className={b({ right: true })}>{item.balance}</div>
    </>
  );
};

export default TransactionItem;
